// import { useActiveWeb3React } from '@hooks/wallet/useActiveWeb3React'
// import WalletManagement from '@layouts/WalletManagement'
// import ChainManagement from '@layouts/ChainManagement'
// import { useTerraWallet } from '@hooks/terra/useTerraWallet'
export const WalletNetworkSection = () => {
    // const { account } = useActiveWeb3React()
    // const { terraAddress } = useTerraWallet()
    return (<>
      {/* {(account || terraAddress) && (
          <div className="inline-block">
            <ChainManagement />
          </div>
        )}
        <div className="inline-block">
          <WalletManagement />
        </div> */}
    </>);
};
